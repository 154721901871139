<!-- 图片管理-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-dropdown @command="switchViewType">
                        <el-link :underline="false">
                            <div class="wrap" >
                                <icon-svg icon-class="icon-chuhuohedui" font-size="27px;"/>
                                <span class="txt">{{msg('选择')}}</span>  
                            </div>              
                        </el-link>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="">{{msg('全部')}}</el-dropdown-item>
                            <el-dropdown-item command="2">{{msg('公开')}}</el-dropdown-item>
                            <el-dropdown-item command="1">{{msg('半公开')}}</el-dropdown-item>
                            <el-dropdown-item command="0">{{msg('私有')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery" v-if="hasPermission('attachment:list')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt">{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" type="primary" @click="doAdd()" v-if="hasPermission('attachment:add')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doDelete()" v-if="hasPermission('attachment:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('文件名')">
                        <el-input v-model="scope.query.filename"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getChildren" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="childrens"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    </div>
   
</template>

<script>
// @ is an alias to /src
import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
 
export default {
  name: 'role',
  components: {
      Pagenator:Pagenator,
      CtrlBox: CtrlBox,
      QueryBox: QueryBox,
  },
  mounted:function(){
    this.getChildren();
  },
  data(){
    return{
        query:{  //查询条件
                length: 10, //每页显示的长度
                start: 0, //起始数量
                pageIndex: 1,
                pageTotal:0,//总记录数
                filename:null
            },
        childrens:[],
        showQuery: false,
        curr: null,
        imgBaseUrl: this.$kit.api.imgView,
        editItem: null,
        uploadUrl: this.$kit.api.upload+"?type=attachment&press=0",
        authType:null,

        columns: [
            { type: 'checkbox', minWidth: 60 },
            {field: "id", title: this.msg("ID"), minWidth: 80, sortable: true},
            {field: "authType", title: this.msg("文件权限"), minWidth: 100, 
                sortable: true,
                slots: {
                        default: ({ row }) => {
                            let authType = this.dicts.authType.find(d => d.value === row.authType);
                            return [
                                <el-tag type={authType?authType.type:''}>{authType?authType.label:row.authType}</el-tag>
                            ];
                        }
                    },
            },
            {field: "category", title: this.msg("文件类型"), minWidth: 100, sortable: true},
            {
                field: 'id',
                title: '内容',
                minWidth: 100,
                slots: {
                    // 使用 JSX 渲染
                    default: ({ row }) => {
                        return [
                          <el-image src={ this.imgBaseUrl+row.id }
                            preview-src-list={ [this.imgBaseUrl+row.id] }
                            style="width: 50px;"></el-image>
                        ]
                    }
                }
            },
            {field: "filename", title: this.msg("文件名"), minWidth: 150, sortable: true},
            {field: "fullname", title: this.msg("创建人"), minWidth: 100, sortable: true},
            {field: "created", title: this.msg("创建时间"), minWidth: 120, sortable: true},
            {field: "remark", title: this.msg("说明"), minWidth: 150, sortable: true}
        ],
      };
  }, 
  computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
            var height =  this.$store.state.docHeight;
            return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
  },
  methods: {
    refresh: function(refresh){  //刷新列表
    },
    getChildren(){

      var _this = this;
      var param = this.$kit.rebuildQuery(this.query);
      if(this.authType){
        param["authType"] = this.authType;
      }

      this.$axios.post(this.$kit.api.attachment.list, param)
            .then(function(result){
                if(result.status){

                    _this.childrens = result.data.list;
                    _this.query.pageTotal = result.data.total;

                }else{
                    _this.$message(result.msg || _this.msg('获取失败'));
                }
               
            });
    },
    deleteRow(row){
    },
    //点击行，选中行
    checkRow({row, $event}){
        //如果点击checkbox 则不处理
        if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
            var table =  this.$refs.dataTable;
            table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
        }
    },
    //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
    handlerPageChange(info){
        this.query.length = info[0];
        this.query.start = info[1];
        this.getChildren();
    },
    switchViewType(command){

        this.authType = command;
        this.getChildren();

    },
    doAdd(){  //点击上传头像
      var _this = this;
      var inputFile = document.createElement("input");
      inputFile.type = "file";
      inputFile.style.display="none";
      inputFile.accept = "image/gif, image/jpeg, image/png";
      inputFile.onchange =  function(e){
        e.preventDefault();
        let formData = new FormData();
        formData.append('file',e.target.files[0]);

        _this.axios.post(_this.uploadUrl, 
            formData, 
            {headers:{'Content-Type':'multipart/form-data'}}
          ).then((result) => {
            if(result.status){
              _this.getChildren();
            }
          }).catch(function (error) {
            console.error(error);
          });
      };

      inputFile.click();

    },
    doEdit(){
    },
    doDelete(){
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
            this.$message(this.msg('请选中一条数据进行操作'));
            return;
        }

        var _this = this;
        this.$confirm(this.fmt('确定要删除文件《%s》吗?', selectes.map(e => e.filename)), this.msg('提示'), {
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
        }).then(() => {
            _this.$axios.post(_this.$kit.api.attachment.delete+"?ids="+selectes.map(e => e.id).join(","))
            .then((result) => {
                _this.$message(result.msg || _this.msg('删除成功'));
                _this.getChildren(true);
            });
        }).catch((err) => {
                // console.log(err);
            });
    },
    reset(){
        this.$kit.clearQuery(this.query);
    },
    handleAvatarSuccess(res, file,fileList) {
        if(res.status){
            this.getChildren(true);
        }else{
            this.$message(res.msg || this.msg("上传失败"));
        }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
